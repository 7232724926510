import { extendTheme } from "@chakra-ui/react"
import { tabsAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/react"

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys)

// define custom variants
const accentColorVariant = definePartsStyle(props => {
  return {
    tab: {
      fontWeight: "500",
      fontSize: "xl",
      _selected: {
        fontWeight: "700",
        borderBottom: "2px solid",
        borderColor: "rlGreen",
        borderBottomColor: "rlGreen",
      },
    },
    tablist: {
      borderBottom: "1px solid",
      borderColor: "rlGreen",
    },
    tabpanel: {
      borderBottom: "1px solid",
      borderColor: "rlGreen",
    },
  }
})

const variants = {
  accentColor: accentColorVariant,
}

const tabsTheme = defineMultiStyleConfig({
  variants,
})

export const theme = extendTheme({
  colors: {
    rlWhite: "#FFFFFF",
    rlIvory: "#E9E9E3",
    rlYellow: "#FFC200",
    rlGreen: "#00B12B",
    rlGrey: "#777777",
    rlBlue: "#2348FF",
    rlRed: "#FF2424",

    rlBlack: "#000000",
    rlBlack40: "#999999",
    rlBlue40: "#A9B8FC",
    rlGreen40: "#A3E0AD",
    rlYellow40: "#FDE7AB",
    rlIvory40: "#F6F6F4",
    rlGray40: "#3F3F3F",

    rlBlack20: "#CCCCCC",
    rlBlue20: "#D3DDFD",
    rlGreen20: "#D1F0D6",
    rlYellow20: "#FFF3D4",
    rlGrey20: "#E9E9E2",

    rlBlue10: "#E5EBFF",
    rlRed10: "#FFD9D9",
    rlYellow10: "#FFF7DD",
    rlGreen10: "#CDEFD5",

    rlBlueDarker: "#0033C1",
    rlGreenDarker: "#258B29",
    rlYellowDarker: "#DE8B2A",
    rlGreyDarker: "#505050",
    rlDividerGrey: "#D9D9D9",
    rlFeedbackIconGrey: "#777777",
    rlCodeBlockDark: "#2d2d2d",
    rlCardGrey: "#F8F8F8",

    rlTableColorScheme: {
      100: "#FBFBFB",
    },
  },

  fonts: {
    heading: `'Helonik', sans-serif`,
    body: `'Helonik', sans-serif`,
  },

  components: {
    Tabs: tabsTheme,
  },
})

export default theme
